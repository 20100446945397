import React from 'react'
import clsx from 'clsx'

const SvgQuizChatQuestion = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 60"
      style={{ enableBackground: 'new 0 0 120 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M77.35 14.012C76.06 12.8295 74.35 12.1787 72.53 12.1787H47.46C43.7 12.1787 40.65 14.9837 40.65 18.4212V41.402C40.65 44.8487 43.71 47.6537 47.46 47.6537H72.53C76.29 47.6537 79.35 44.8487 79.35 41.402V18.4212C79.35 16.762 78.64 15.1945 77.35 14.012ZM72.54 46.0954H47.46C44.64 46.0954 42.35 43.9962 42.35 41.402V18.4212C42.35 15.8362 44.64 13.737 47.46 13.737H72.53C75.35 13.737 77.65 15.8362 77.65 18.4212V41.402C77.65 43.9962 75.36 46.0954 72.54 46.0954Z" />
      <path d="M60 19.5029C56.84 19.5029 54.27 21.8588 54.27 24.7554C54.27 25.1863 54.65 25.5346 55.12 25.5346C55.59 25.5346 55.97 25.1863 55.97 24.7554C55.97 22.7204 57.78 21.0613 60 21.0613C62.22 21.0613 64.03 22.7204 64.03 24.7554C64.03 25.9654 63.1 26.7629 61.68 27.8354C60.44 28.7704 59.15 29.7421 59.15 31.2821V34.9579C59.15 35.3888 59.53 35.7371 60 35.7371C60.47 35.7371 60.85 35.3888 60.85 34.9579V31.2821C60.85 30.4846 61.73 29.8154 62.76 29.0363C64.15 27.9821 65.73 26.7996 65.73 24.7554C65.73 23.3529 65.13 22.0421 64.05 21.0429C62.97 20.0438 61.53 19.5029 60 19.5029Z" />
      <path d="M60 38.1201C59.34 38.1201 58.8 38.6151 58.8 39.2201C58.8 39.8251 59.34 40.3201 60 40.3201C60.66 40.3201 61.2 39.8251 61.2 39.2201C61.2 38.6151 60.66 38.1201 60 38.1201Z" />
    </svg>
  )
}

export default SvgQuizChatQuestion
