import * as yup from 'yup'
import 'yup-phone'
import { yupResolver } from '@hookform/resolvers/yup'

export const QuizSchema = yup.object().shape({
  field_136678450: yup.string().label('Company Size'),
  field_136678453: yup.string().label('Custom Design'),
  field_136678461: yup.string().label('Website Estimate'),
  field_136678464: yup.string().label('Website Maintenance'),
  field_136678525: yup.array().label('Premium Features'),
  field_136678538: yup.string().email().label('Emaill Address'),
  field_136678539: yup.string().phone().label('Phone'),
  field_136678543: yup.string().required().label('First Name'),
  field_136678541: yup.string().required().label('Last Name'),
  automaton: yup.string(),
  field_136678585: yup.string().label('Lead Name'),
})

export const QuizResolver = {
  schema: QuizSchema,
  resolver: yupResolver(QuizSchema),
}
