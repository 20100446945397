import React from 'react'
import clsx from 'clsx'

const SvgQuizCms = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 60"
      style={{ enableBackground: 'new 0 0 120 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <g clipPath="url(#clip0_2554_172859)">
        <path d="M40.2504 25.7568H47.0312L47.104 25.9648C47.4784 27.0152 48.4768 27.7224 49.5896 27.7224C50.7024 27.7224 51.7008 27.0152 52.0752 25.9648L52.148 25.7568H54.3112C54.6856 25.7568 54.9768 25.4552 54.9768 25.0912C54.9768 24.7272 54.6752 24.4256 54.3112 24.4256H52.148L52.0752 24.2176C51.7008 23.1672 50.7024 22.46 49.5896 22.46C48.4768 22.46 47.4784 23.1672 47.104 24.2176L47.0312 24.4256H40.2504C39.876 24.4256 39.5848 24.7272 39.5848 25.0912C39.5848 25.4552 39.876 25.7568 40.2504 25.7568ZM49.5792 23.7912C50.2968 23.7912 50.8688 24.3736 50.8688 25.0808C50.8688 25.788 50.2864 26.3704 49.5792 26.3704C48.8616 26.3704 48.2896 25.788 48.2896 25.0808C48.2896 24.3736 48.872 23.7912 49.5792 23.7912Z" />
        <path d="M40.2504 41.4814H44.764L44.8368 41.6894C45.2112 42.7398 46.2096 43.447 47.3224 43.447C48.4352 43.447 49.4336 42.7398 49.808 41.6894L49.8808 41.4814H54.3008C54.6752 41.4814 54.9664 41.1798 54.9664 40.8158C54.9664 40.4518 54.6648 40.1502 54.3008 40.1502H49.8808L49.808 39.9422C49.4336 38.8918 48.4352 38.1846 47.3224 38.1846C46.2096 38.1846 45.2112 38.8918 44.8368 39.9422L44.764 40.1502H40.2504C39.876 40.1502 39.5848 40.4518 39.5848 40.8158C39.5848 41.1798 39.876 41.4814 40.2504 41.4814ZM47.3224 39.5158C48.04 39.5158 48.612 40.0982 48.612 40.8054C48.612 41.5126 48.0296 42.095 47.3224 42.095C46.6152 42.095 46.0328 41.5126 46.0328 40.8054C46.0328 40.0982 46.6048 39.5158 47.3224 39.5158Z" />
        <path d="M42.216 35.5853C43.3288 35.5853 44.3272 34.8781 44.7016 33.8277L44.7744 33.6197H54.3008C54.6752 33.6197 54.9664 33.3181 54.9664 32.9541C54.9664 32.5901 54.6648 32.2885 54.3008 32.2885H44.7744L44.7016 32.0805C44.3272 31.0197 43.3288 30.3125 42.216 30.3125C40.76 30.3125 39.5848 31.4981 39.5848 32.9437C39.5848 34.3893 40.76 35.5853 42.216 35.5853ZM42.216 31.6541C42.9336 31.6541 43.5056 32.2365 43.5056 32.9437C43.5056 33.6509 42.9232 34.2333 42.216 34.2333C41.5088 34.2333 40.9264 33.6509 40.9264 32.9437C40.9264 32.2365 41.4984 31.6541 42.216 31.6541Z" />
        <path d="M62.1632 33.6192H80.0512C80.4256 33.6192 80.7168 33.3176 80.7168 32.9536V23.1256C80.7168 22.7512 80.4152 22.46 80.0512 22.46H62.1632C61.7888 22.46 61.4976 22.7616 61.4976 23.1256V32.9536C61.4872 33.3176 61.7888 33.6192 62.1632 33.6192ZM62.8288 23.7912H79.3752V32.2776H62.8288V23.7912Z" />
        <path d="M82.0168 10.6562H37.9832C36.5376 10.6562 35.352 11.8419 35.352 13.2979V48.6787C35.352 49.0531 35.6536 49.3443 36.0176 49.3443H75.5168H81.6112H83.9616C84.336 49.3443 84.6272 49.0427 84.6272 48.6787V13.2979C84.648 11.8419 83.4624 10.6562 82.0168 10.6562ZM57.556 48.0027H36.6936V19.8603H57.556V48.0027ZM83.3064 48.0027H81.6216H75.5584H58.8976V19.8603H83.3064V48.0027ZM83.3064 18.5187H36.6936V13.2979C36.6936 12.5803 37.276 12.0083 37.9832 12.0083H82.0064C82.724 12.0083 83.296 12.5907 83.296 13.2979V18.5187H83.3064Z" />
        <path d="M62.1632 43.0519H73.9568C74.3312 43.0519 74.6224 42.7503 74.6224 42.3863C74.6224 42.0223 74.3208 41.7207 73.9568 41.7207H62.1632C61.7888 41.7207 61.4976 42.0223 61.4976 42.3863C61.4976 42.7503 61.7888 43.0519 62.1632 43.0519Z" />
        <path d="M62.1632 38.3302H80.0512C80.4256 38.3302 80.7168 38.0286 80.7168 37.6646C80.7168 37.3006 80.4152 36.999 80.0512 36.999H62.1632C61.7888 36.999 61.4976 37.3006 61.4976 37.6646C61.4976 38.0286 61.7888 38.3302 62.1632 38.3302Z" />
      </g>
      <defs>
        <clipPath id="clip0_2554_172859">
          <rect width="120" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgQuizCms
