import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import clsx from 'clsx'
import { getImage } from 'gatsby-plugin-image'
import { useForm, useWatch } from 'react-hook-form'
import axios from 'axios'
import {
  useHomePageStaticData,
  QuizComparisonItems,
} from '@hooks/home-page-static'
import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'

import { QuizResolver } from '@src/resolvers/Quiz'

import ElementImage from '@components/global/element/Image'
import ElementImageFile from '@components/global/element/ImageFile'
import Modal from '@components/global/modal/Index'
import CustomInputs from './CustomInputs'
import Input from '@components/global/forms/fields/Input'

import * as cx from './QuizComparisonV2.module.scss'

import SvgCloseThin from '@svg/closeThin.inline'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import SvgQuizPerson from '@svg/quiz/person.inline'
import SvgQuizPersons from '@svg/quiz/persons.inline'
import SvgQuizTeam from '@svg/quiz/team.inline'
import SvgQuizBuilding from '@svg/quiz/building.inline'
import SvgQuizPencil from '@svg/quiz/pencil.inline'
import SvgQuizWebsiteDesign from '@svg/quiz/website-design.inline'
import SvgQuizWebsiteLayout from '@svg/quiz/website-layout.inline'
import SvgQuizTimer from '@svg/quiz/timer.inline'
import SvgQuizCalendar from '@svg/quiz/calendar.inline'
import SvgQuizTimerHand from '@svg/quiz/timerHand.inline'
import SvgQuizCms from '@svg/quiz/cms.inline'
import SvgQuizChatSetting from '@svg/quiz/chat-setting.inline'
import SvgQuizChatQuestion from '@svg/quiz/chat-question.inline'
import SvgQuizLeadGen from '@svg/quiz/lead-gen.inline'
import SvgQuizBranding from '@svg/quiz/branding.inline'
import SvgQuizMap from '@svg/quiz/map.inline'
import SvgQuizAnalytics from '@svg/quiz/analytics.inline'
import SvgQuizChat from '@svg/quiz/chat.inline'
import SvgQuizNeighborhood from '@svg/quiz/neighborhood.inline'
import SvgQuizIdx from '@svg/quiz/idx.inline'
import SvgQuizSecurity from '@svg/quiz/security.inline'
import SvgQuizAccessibility from '@svg/quiz/accessibility.inline'

const ProductQuizComparisonV2 = ({ wrapperClass }) => {
  const data = useHomePageStaticData()

  const [submitting, setSubmitting] = useState(false)

  const {
    control,
    register,
    resetField,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: QuizResolver.resolver,
  })

  useEffect(() => {
    setValue('field_136678585', 'Homepage Quiz')
  }, [])

  // Form
  const formFunc = useRef()

  // Modal
  const modalFunc = useRef()

  // Step
  const [step, setStep] = useState(1)
  const maxStep = 7

  // Fields
  const [companySize, setCompanySize] = useState('')
  const [customDesign, setCustomDesign] = useState('')
  const [howLongToWait, setHowLongToWait] = useState('')
  const [websiteMaintenance, setWebsiteMaintenance] = useState('')
  const [premiumFeatures, setPremiumFeatures] = useState([])

  const fieldEmailAddress = useWatch({
    control,
    name: 'field_136678538',
    defaultValue: '',
  })
  const fieldPhone = useWatch({
    control,
    name: 'field_136678539',
    defaultValue: '',
  })
  const fieldFirstName = useWatch({
    control,
    name: 'field_136678543',
    defaultValue: '',
  })
  const fieldLastName = useWatch({
    control,
    name: 'field_136678541',
    defaultValue: '',
  })

  const prevStep = () => {
    if (step !== 1) {
      setStep(step - 1)
    } else {
      modalFunc.current.closeModal()
    }
  }

  const nextStep = () => {
    if (step !== maxStep) {
      setStep(step + 1)
    }
  }

  const onSubmit = async (data) => {
    setStep(step + 1)
    setSubmitting(true)
    let newData = data

    if (data['field_136678525'] !== undefined) {
      newData['field_136678525'] = data['field_136678525'].join(', ')
    }

    await axios
      .post(
        `${process.env.GATSBY_FORMSTACK_API}/${process.env.GATSBY_FORMSTACK_KEY}/5106493`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        }
      )
      .then((res) => {
        // alert('Successfully, Redirect if page is converted')
        reset()
        modalFunc.current.closeModal()
        setStep(1)
        navigate('whats-the-right-website-for-me/?product-type=semicustom')
      })
      .catch((err) => {
        alert('Error')
      })

    setSubmitting(false)
  }

  return (
    <section id="product-quiz-comparison" className={clsx(wrapperClass)}>
      <div
        className={clsx(cx.quiz)}
        style={{
          backgroundImage: `url(${extractGatsbyImageDataRelativePath(
            data.quizBackground
          )})`,
        }}
      >
        <div className={clsx(cx.content)}>
          <div className={clsx(cx.headingForm)}>
            <div className={clsx(cx.heading)}>
              <h2 className="heading-2">What’s The Right Website For Me?</h2>
              <p className="subtitle-5">
                Take our short quiz to help you choose the right website.
              </p>
              <p className="subtitle-5">
                Whether you’re a rookie or a rockstar agent, <br />
                there’s a perfect website package for you.
              </p>
            </div>

            <div className={clsx(cx.mobileImage, 'mobile-only')}>
              <ElementImageFile src={getImage(data.quizImage)} alt="Quiz" />
            </div>

            <div className={clsx(cx.form)}>
              <h4 className="subtitle-7">Tell us about yourself</h4>
              <h3 className="subtitle-2">How big is your company?</h3>

              <CustomInputs
                layout="column"
                value={companySize}
                items={[
                  {
                    icon: <SvgQuizPerson />,
                    title: 'Solo Realtor',
                    description: 'Just me!',
                  },
                  {
                    icon: <SvgQuizPersons />,
                    title: 'Small Team',
                    description: '5-10 agents',
                  },
                  {
                    icon: <SvgQuizTeam />,
                    title: 'Large Team',
                    description: '10-50 agents',
                  },
                  {
                    icon: <SvgQuizBuilding />,
                    title: 'Enterprise',
                    description: '50+ agents',
                  },
                ]}
                changeState={(v) => {
                  setCompanySize(v)
                  setValue('field_136678450', v)
                }}
                hidePrev={true}
                onNext={() => {
                  modalFunc.current.openModal()
                }}
              />

              <Modal
                childFunc={modalFunc}
                containerClassName={cx.modalContainer}
                contentClassName={clsx(
                  cx.modalContent,
                  step === 4 && cx.large,
                  (step === 5 || step === 6 || step === 7) && cx.medium
                )}
              >
                <button
                  aria-label="Close Modal"
                  type="button"
                  className={clsx(cx.closeButton)}
                  onClick={() => {
                    modalFunc.current.closeModal()
                  }}
                >
                  <SvgCloseThin />
                  <span className="hidden">Close</span>
                </button>
                <div className={clsx(cx.borderGradient)}>
                  <div style={{ width: `${(100 / maxStep) * step}%` }}></div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* Robot Verifier & Lead Name */}
                  <div className="hidden">
                    <Input
                      register={register}
                      label="Automaton"
                      name="automaton"
                      type="text"
                      error={errors.automaton?.message}
                      isRequired={false}
                    />
                    <Input
                      register={register}
                      label="Lead Name"
                      name="field_136678585"
                      type="text"
                      error={errors.field_136678585?.message}
                      isRequired={false}
                    />
                  </div>

                  {/* Step #1 */}
                  <h2 className={clsx('subtitle-2', step !== 1 && 'hidden')}>
                    What level of <br />
                    custom design do you need?
                  </h2>
                  <CustomInputs
                    wrapperClassName={clsx(step !== 1 && 'hidden')}
                    value={customDesign}
                    items={[
                      {
                        icon: (
                          <SvgQuizPencil
                            strokeColor={
                              customDesign === 'Low' ? '#FFFFFF' : '#009BBB'
                            }
                          />
                        ),
                        title: 'Low',
                        description: 'Just add my logo to a template design!',
                      },
                      {
                        icon: <SvgQuizWebsiteDesign />,
                        title: 'Moderate',
                        description: 'Customizing a few website pages',
                      },
                      {
                        icon: <SvgQuizWebsiteLayout />,
                        title: 'High',
                        description: 'Most website pages will be custom-built',
                      },
                    ]}
                    changeState={(v) => {
                      setCustomDesign(v)
                      setValue('field_136678453', v)
                    }}
                    onPrev={prevStep}
                    onNext={nextStep}
                  />

                  {/* Step #2 */}
                  <h2 className={clsx('subtitle-2', step !== 2 && 'hidden')}>
                    How long can you wait for <br />
                    your new website?
                  </h2>
                  <CustomInputs
                    wrapperClassName={clsx(step !== 2 && 'hidden')}
                    value={howLongToWait}
                    items={[
                      {
                        icon: <SvgQuizTimer />,
                        title: 'A couple weeks',
                        description: 'I want a website real quick!',
                      },
                      {
                        icon: <SvgQuizCalendar />,
                        title: 'About 1 month',
                        description: 'I need it fairly soon',
                      },
                      {
                        icon: <SvgQuizTimerHand />,
                        title: '2+ months',
                        description:
                          'I want to take the time to perfect my website',
                      },
                    ]}
                    changeState={(v) => {
                      setHowLongToWait(v)
                      setValue('field_136678461', v)
                    }}
                    onPrev={prevStep}
                    onNext={nextStep}
                  />

                  {/* Step #3 */}
                  <h2 className={clsx('subtitle-2', step !== 3 && 'hidden')}>
                    Are you comfortable <br />
                    maintaining your website?
                  </h2>
                  <CustomInputs
                    wrapperClassName={clsx(step !== 3 && 'hidden')}
                    value={websiteMaintenance}
                    items={[
                      {
                        icon: <SvgQuizCms />,
                        title: 'Yes!',
                        description: 'I know my way around a CMS',
                      },
                      {
                        icon: <SvgQuizChatSetting />,
                        title: 'Partly yes and no',
                        description:
                          'I need help with the more complicated stuff',
                      },
                      {
                        icon: <SvgQuizChatQuestion />,
                        title: 'Not really',
                        description: 'I’d rather outsource my web management',
                      },
                    ]}
                    changeState={(v) => {
                      setWebsiteMaintenance(v)
                      setValue('field_136678464', v)
                    }}
                    onPrev={prevStep}
                    onNext={nextStep}
                  />

                  {/* Step #4 */}
                  <div className={clsx(cx.subHeading, step !== 4 && 'hidden')}>
                    <h2 className="subtitle-2">
                      Are you interested in these <br />
                      premium features?
                    </h2>
                    <p className="default-body large">
                      Choose as many as you want.
                    </p>
                  </div>
                  <CustomInputs
                    wrapperClassName={clsx(step !== 4 && 'hidden')}
                    layout="column-three"
                    inputType="checkbox"
                    value={premiumFeatures}
                    items={[
                      {
                        icon: <SvgQuizLeadGen />,
                        title: 'Lead Generation Tools',
                      },
                      {
                        icon: <SvgQuizBranding />,
                        title: 'Branding & <br />Logo Design',
                      },
                      {
                        icon: <SvgQuizMap />,
                        title: 'Interactive Area Maps',
                      },
                      {
                        icon: <SvgQuizAnalytics />,
                        title: 'Search Engine Optimization',
                      },
                      {
                        icon: <SvgQuizChat />,
                        title: '24/7 Website Support',
                      },
                      {
                        icon: <SvgQuizNeighborhood />,
                        title: 'Neighborhood Guides',
                      },
                      {
                        icon: <SvgQuizIdx />,
                        title: 'Your Preferred <br />IDX Provider',
                      },
                      {
                        icon: (
                          <SvgQuizSecurity
                            strokeColor={
                              premiumFeatures.includes(
                                'Advanced Security Features'
                              )
                                ? '#FFFFFF'
                                : '#009BBB'
                            }
                          />
                        ),
                        title: 'Advanced Security Features',
                      },
                      {
                        icon: <SvgQuizAccessibility />,
                        title: 'Accessibility & Legal Compliance',
                      },
                    ]}
                    changeState={(v) => {
                      // setPremiumFeatures(v)
                      if (!premiumFeatures.includes(v)) {
                        setValue('field_136678525', [...premiumFeatures, v])
                        setPremiumFeatures((premium) => [...premium, v])
                      } else {
                        setValue(
                          'field_136678525',
                          premiumFeatures.filter((premium) => {
                            return premium !== v
                          })
                        )
                        setPremiumFeatures((current) =>
                          current.filter((premium) => {
                            return premium !== v
                          })
                        )
                      }
                    }}
                    onPrev={prevStep}
                    onNext={nextStep}
                  />

                  {/* Step #5 */}
                  <div className={clsx(cx.subHeading, step !== 5 && 'hidden')}>
                    <h2 className="subtitle-2">Almost there!</h2>
                    <p className="default-body large">
                      Please tell us where we can send more info <br />
                      about your recommended website package.
                    </p>
                  </div>
                  <div className={clsx(cx.formRow, step !== 5 && 'hidden')}>
                    <div className="form-row">
                      <div className="form-col">
                        <Input
                          register={register}
                          label="Email"
                          name="field_136678538"
                          type="email"
                          placeholder="Your email here.."
                          error={errors.field_136678538?.message}
                          isRequired={false}
                          isLabelHidden={false}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col">
                        <Input
                          register={register}
                          label="Phone"
                          name="field_136678539"
                          type="text"
                          placeholder="Your number.."
                          error={errors.field_136678539?.message}
                          isRequired={false}
                          isLabelHidden={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx(cx.prevNextButton, step !== 5 && 'hidden')}
                  >
                    <button
                      type="button"
                      className={clsx(
                        'primary-button',
                        'large',
                        'alt',
                        cx.prev
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        prevStep()
                      }}
                    >
                      <SvgArrowAlt />
                      <span className="mobile-no-tablet-only">
                        Back to previous
                      </span>
                    </button>
                    <button
                      type="button"
                      className={clsx(
                        'primary-button',
                        'large',
                        (fieldEmailAddress === '' ||
                          errors.field_136678538 !== undefined ||
                          fieldPhone === '' ||
                          errors.field_136678539 !== undefined) &&
                          'inactive'
                      )}
                      disabled={
                        fieldEmailAddress === '' ||
                        errors.field_136678538 !== undefined ||
                        fieldPhone === '' ||
                        errors.field_136678539 !== undefined
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        nextStep()
                      }}
                    >
                      Continue to Next
                    </button>
                  </div>

                  {/* Step #6 */}
                  <div className={clsx(cx.subHeading, step !== 6 && 'hidden')}>
                    <h2 className="subtitle-2">And finally...</h2>
                    <p className="default-body large">
                      Let us know how to address you!
                    </p>
                  </div>
                  <div className={clsx(cx.formRow, step !== 6 && 'hidden')}>
                    <div className="form-row">
                      <div className="form-col">
                        <Input
                          register={register}
                          label="First Name"
                          name="field_136678543"
                          type="text"
                          placeholder="Your first name.."
                          error={errors.field_136678543?.message}
                          isRequired={false}
                          isLabelHidden={false}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-col">
                        <Input
                          register={register}
                          label="Last Name"
                          name="field_136678541"
                          type="text"
                          placeholder="Your last name.."
                          error={errors.field_136678541?.message}
                          isRequired={false}
                          isLabelHidden={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx(cx.prevNextButton, step !== 6 && 'hidden')}
                  >
                    <button
                      type="button"
                      className={clsx(
                        'primary-button',
                        'large',
                        'alt',
                        cx.prev
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        prevStep()
                      }}
                    >
                      <SvgArrowAlt />
                      <span className="mobile-no-tablet-only">
                        Back to previous
                      </span>
                    </button>
                    <button
                      type="submit"
                      className={clsx(
                        'primary-button',
                        'large',
                        (fieldFirstName === '' || fieldLastName === '') &&
                          'inactive'
                      )}
                      disabled={fieldFirstName === '' || fieldLastName === ''}
                    >
                      Continue to Next
                    </button>
                  </div>

                  {/* Step #7 */}
                  <div
                    className={clsx(cx.subHeadingLast, step !== 7 && 'hidden')}
                  >
                    <ElementImage
                      src="https://resources.agentimage.com/redesign/local/quiz-comparison/loader.gif"
                      alt="Matching You With the Perfect Website Package"
                    />
                    <h2 className="subtitle-2">
                      Matching You With the <br />
                      Perfect Website Package
                    </h2>
                    <p className="default-body large">
                      Please wait for a few seconds..
                    </p>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
          <div className={clsx(cx.image, 'desktop-only')}>
            <ElementImageFile src={getImage(data.quizImage)} alt="Quiz" />
          </div>
        </div>
      </div>
    </section>
  )
}

ProductQuizComparisonV2.propTypes = {
  wrapperClass: PropTypes.string,
}

export default ProductQuizComparisonV2
