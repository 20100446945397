import React from 'react'
import PropTypes from 'prop-types'

import DeviceTablet from '../Tablet'
import * as cx from './TrioTablet.module.scss'

const TrioTablet = ({
  wrapperClass = '',
  upperSrc,
  middleSrc,
  lowerSrc,
  alt = '',
  lazy = true,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={825} height={370} className={cx.placeholder}></canvas>
      <DeviceTablet
        className={`${cx.upper} tablet-upper`}
        imageFile={upperSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.middle} tablet-middle`}
        imageFile={middleSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.lower} tablet-lower`}
        imageFile={lowerSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
    </div>
  )
}

TrioTablet.propTypes = {
  wrapperClass: PropTypes.string,
  upperSrc: PropTypes.object,
  middleSrc: PropTypes.object,
  lowerSrc: PropTypes.object,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
}

export default TrioTablet
