import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTablet from '../Tablet'
import DeviceLaptop from '../Laptop'
import * as cx from './LaptopTabletMobile.module.scss'

const LaptopTabletMobile = ({
  wrapperClass = '',
  mobileSrc,
  tabletSrc,
  laptopSrc,
  alt = '',
  lazy = true,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={885} height={403} className={cx.placeholder}></canvas>
      <DeviceMobile
        className={`${cx.mobile} mobile`}
        imageFile={mobileSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.tablet} ipad-landscape`}
        imageFile={tabletSrc}
        imageFileAlt={`${alt} ipad`}
        lazy={lazy}
      />
      <DeviceLaptop
        className={`${cx.laptop} laptop`}
        imageFile={laptopSrc}
        imageFileAlt={`${alt} laptop`}
        lazy={lazy}
      />
    </div>
  )
}

LaptopTabletMobile.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  laptopSrc: PropTypes.object,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
}

export default LaptopTabletMobile
