import React from 'react'
import clsx from 'clsx'

const SvgQuizBuilding = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 131 60"
      style={{ enableBackground: 'new 0 0 131 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <g clipPath="url(#clip0_2406_174331)">
        <path d="M78.5 20H70.5V22H78.5V43H70.5V45H80.5V22C80.5 21.4696 80.2893 20.9609 79.9142 20.5858C79.5391 20.2107 79.0304 20 78.5 20Z" />
        <path d="M67.38 15H53.62C53.0577 15 52.5185 15.2234 52.1209 15.6209C51.7234 16.0185 51.5 16.5577 51.5 17.12V45H69.5V17.12C69.5 16.5577 69.2766 16.0185 68.8791 15.6209C68.4815 15.2234 67.9423 15 67.38 15ZM67.5 43H64.5V40H56.5V43H53.5V17.12C53.5 17.1042 53.5031 17.0886 53.5091 17.0741C53.5152 17.0595 53.524 17.0463 53.5351 17.0351C53.5463 17.024 53.5595 17.0152 53.5741 17.0091C53.5886 17.0031 53.6042 17 53.62 17H67.38C67.3958 17 67.4114 17.0031 67.4259 17.0091C67.4405 17.0152 67.4537 17.024 67.4649 17.0351C67.476 17.0463 67.4848 17.0595 67.4909 17.0741C67.4969 17.0886 67.5 17.1042 67.5 17.12V43Z" />
        <path d="M55.5 20H57.5V22H55.5V20Z" />
        <path d="M59.5 20H61.5V22H59.5V20Z" />
        <path d="M63.5 20H65.5V22H63.5V20Z" />
        <path d="M55.5 25H57.5V27H55.5V25Z" />
        <path d="M59.5 25H61.5V27H59.5V25Z" />
        <path d="M63.5 25H65.5V27H63.5V25Z" />
        <path d="M55.5 30H57.5V32H55.5V30Z" />
        <path d="M59.5 30H61.5V32H59.5V30Z" />
        <path d="M63.5 30H65.5V32H63.5V30Z" />
        <path d="M55.5 35H57.5V37H55.5V35Z" />
        <path d="M59.5 35H61.5V37H59.5V35Z" />
        <path d="M63.5 35H65.5V37H63.5V35Z" />
        <path d="M70.5 25H72.5V27H70.5V25Z" />
        <path d="M74.5 25H76.5V27H74.5V25Z" />
        <path d="M70.5 30H72.5V32H70.5V30Z" />
        <path d="M74.5 30H76.5V32H74.5V30Z" />
        <path d="M70.5 35H72.5V37H70.5V35Z" />
        <path d="M74.5 35H76.5V37H74.5V35Z" />
      </g>
      <defs>
        <clipPath id="clip0_2406_174331">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(47.5 12)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgQuizBuilding
