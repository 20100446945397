import React from 'react'
import clsx from 'clsx'

const SvgQuizCalendar = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 60"
      style={{ enableBackground: 'new 0 0 120 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M85.0253 11.9113C84.0845 11.3401 83.0429 11.2281 81.6765 11.2281C81.4749 11.2281 81.2621 11.2281 81.0605 11.2281C80.8477 11.2281 80.6349 11.2281 80.4333 11.2281H79.8733V7.67773H77.4093V11.1945H52.6797V7.67773H50.2381V11.2281H49.6781C49.5549 11.2281 49.0621 11.2281 48.9389 11.2281C47.2701 11.2281 45.7805 11.3065 44.5597 12.2361C43.4173 13.1097 42.7677 14.4985 42.7117 16.1449C42.6109 19.2809 42.6445 22.4953 42.6781 25.5977C42.6893 27.1209 42.7117 28.6889 42.7117 30.2345C42.7117 32.2281 42.7117 34.2329 42.7117 36.2265C42.7117 38.9705 42.7117 41.7257 42.7117 44.4697C42.7117 44.8057 42.7005 45.1641 42.7005 45.5001C42.6669 47.0793 42.6221 48.7033 43.4173 50.0249C44.1117 51.1785 45.2093 51.8841 46.9565 52.3321H83.1325C84.4317 52.0409 85.3725 51.5481 86.0781 50.7977C86.7277 50.1033 86.9629 49.3641 87.2653 48.4233C87.2989 48.3001 87.3437 48.1881 87.3773 48.0649V15.4617C86.9853 13.7929 86.2125 12.6281 85.0253 11.9113ZM84.9021 47.1801C84.9021 48.9385 83.9725 49.8681 82.2029 49.8681H47.8189C46.0717 49.8681 45.1421 48.9497 45.1421 47.2025C45.1421 41.6025 45.1421 23.3577 45.1421 23.3577H84.8909L84.9021 47.1801ZM84.9021 18.9897C84.9021 19.4265 84.9021 19.8521 84.9021 20.2889V20.8489H45.1645V20.2889C45.1645 19.8521 45.1645 19.4153 45.1645 18.9785C45.1645 17.9705 45.1533 16.9289 45.1757 15.9097C45.1981 14.7225 46.1725 13.7481 47.3709 13.6921C47.6285 13.6809 47.9085 13.6697 48.2445 13.6697C48.4685 13.6697 48.6925 13.6697 48.9277 13.6697C49.1629 13.6697 49.3981 13.6697 49.6333 13.6697H50.1933V17.2313H52.6573V13.7145H77.3869V17.2313H79.8397V13.7033H80.3997C80.6573 13.7033 80.9149 13.7033 81.1613 13.6921C81.4077 13.6921 81.6653 13.6809 81.9117 13.6809C82.2925 13.6809 82.6061 13.6921 82.8973 13.7145C84.0173 13.8153 84.8797 14.7561 84.9021 15.9097C84.9133 16.9401 84.9133 17.9817 84.9021 18.9897Z" />
      <path d="M75.7754 29.2719L73.9498 27.6367L62.4026 40.5727L55.9178 34.3679L54.2266 36.1487L62.537 44.1119L75.7754 29.2719Z" />
    </svg>
  )
}

export default SvgQuizCalendar
