import React from 'react'

const SvgCloseThin = () => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      style={{ enableBackground: 'new 0 0 22 22' }}
      className="fill-current"
      aria-labelledby="Close"
    >
      <title>Close</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.935089 0.743683C1.1656 0.513173 1.53933 0.513173 1.76984 0.743683L11.0009 9.97474L20.232 0.743683C20.4625 0.513173 20.8362 0.513173 21.0667 0.743683C21.2972 0.974193 21.2972 1.34792 21.0667 1.57843L11.8356 10.8095L21.0667 20.0405C21.2972 20.2711 21.2972 20.6448 21.0667 20.8753C20.8362 21.1058 20.4625 21.1058 20.232 20.8753L11.0009 11.6442L1.76984 20.8753C1.53933 21.1058 1.1656 21.1058 0.935089 20.8753C0.70458 20.6448 0.70458 20.2711 0.935089 20.0405L10.1661 10.8095L0.935089 1.57843C0.70458 1.34792 0.70458 0.974193 0.935089 0.743683Z"
      />
    </svg>
  )
}

export default SvgCloseThin
