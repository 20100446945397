import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMedia, { media } from '@hooks/useMedia'
import { useHomePageMetadata } from '@hooks/home-page-metadata'
import { getImage } from 'gatsby-plugin-image'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { autobr } from '@utils/autobr'
import { wpautop } from '@utils/wpautop'
import { debounce } from '@utils/debounce'
import parse from 'html-react-parser'

import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import TabletLandscapePortraitPhone from '@components/global/devices/TabletLandscapePortraitPhone'
import LaptopTabletMobile from '@components/global/devices/LaptopTabletMobile'
import TrioTablet from '@components/global/devices/TrioTablet'
import VideoThumbnail from '@components/global/modal/VideoThumbnail'
import ElementImage from '@components/global/element/Image'
import SvgLogoMono from '@svg/mono.inline'
import * as cx from './Banner.module.scss'

const HomeBanner = () => {
  const [isMobile, setIsMobile] = useState(false)
  const modalRef = useRef()
  const { logo, devices, video } = useHomePageMetadata().wp.Banner

  useEffect(() => {
    let isMounted = true

    const detectSize = debounce(() => {
      if (isMounted) {
        setIsMobile(window.innerWidth <= 1023)
      }
    }, 50)

    detectSize()

    window.addEventListener('resize', detectSize)

    return () => {
      isMounted = false
      window.removeEventListener('resize', detectSize)
    }
  })

  const isTablet = useMedia(media.tablet)

  return (
    <section className={cx.wrapper}>
      <ModalForm
        childFunc={modalRef}
        desktopTitle="Ready To Build An <br>Award-Winning Website?"
        mobileTitle="Ready To Build <br />An Award-Winning <br />Website?"
        template={isMobile ? 'alternative' : 'default'}
      >
        <FormSignUp
          submitLabel="Unlock next-level growth!"
          labelClassName="desktop-only"
          leadName="HP Modal Form Sign Up"
        />
      </ModalForm>

      <SvgLogoMono className={cx.mono} />
      <Splide
        hasTrack={false}
        tag="div"
        options={{
          type: 'fade',
          rewind: true,
          arrows: false,
          pagination: true,
          perPage: 1,
          autoHeight: true,
          lazyLoad: 'sequential',
          autoplay: false,
          interval: 5000,
        }}
        className={cx.sliders}
      >
        <SplideTrack>
          {devices.map((item, index) => {
            return (
              <SplideSlide key={index}>
                <ul
                  className={`${cx.content} ${item.device_style} ${item.gradient}`}
                >
                  <li>
                    <h2
                      className={isTablet ? 'heading-3 flex' : 'heading-1 flex'}
                    >
                      {parse(autobr(item.title))}
                    </h2>

                    {parse(wpautop(item.description, 'subtitle-5'))}

                    <button
                      className="primary-button large"
                      onClick={(e) => modalRef.current.openModal()}
                    >
                      {parse(item.button_text)}
                    </button>
                  </li>
                  <li>
                    {item.device_style ===
                      'phone-tablet-landscape-portrait' && (
                      <TabletLandscapePortraitPhone
                        wrapperClass={cx.deviceV1}
                        mobileSrc={item.device_3}
                        tabletLandscapeSrc={item.device_1}
                        tabletPortraitSrc={item.device_2}
                        alt={item.title}
                        lazy={index !== 0}
                      />
                    )}
                    {item.device_style === 'phone-tablet-laptop' && (
                      <LaptopTabletMobile
                        wrapperClass={cx.deviceV2}
                        mobileSrc={item.device_3}
                        tabletSrc={item.device_2}
                        laptopSrc={item.device_1}
                        alt={item.title}
                        lazy={index !== 0}
                      />
                    )}
                    {item.device_style === 'triple-tablet' && (
                      <TrioTablet
                        wrapperClass={cx.deviceV3}
                        upperSrc={item.device_1}
                        middleSrc={item.device_2}
                        lowerSrc={item.device_3}
                        alt={item.title}
                        lazy={index !== 0}
                      />
                    )}
                  </li>
                </ul>
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>

      <div className={cx.topAgents}>
        <Splide
          hasTrack={false}
          tag="div"
          options={{
            type: 'loop',
            arrows: false,
            pagination: false,
            perPage: 3,
            perMove: 1,
            autoHeight: false,
            autoplay: true,
            interval: 2500,
            breakpoints: {
              1023: {
                perPage: 2,
              },
              500: {
                perPage: 1,
              },
            },
          }}
          className={cx.carousel}
        >
          <SplideTrack>
            {logo.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <ElementImage
                    src={item.image.url}
                    width={item.image.width}
                    height={item.image.height}
                    alt={item.image.alt}
                  />
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>

        <VideoThumbnail
          wrapperClass={cx.videoThumbnail}
          video={video[0].url}
          thumbnail={video[0].image}
          image_data={true}
          title="Client Reviews"
          button_text="Why Agent Image"
          lazy={false}
        />
      </div>
    </section>
  )
}

export default HomeBanner
