import React from 'react'
import clsx from 'clsx'

const SvgQuizNeighborhood = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      style={{ enableBackground: 'new 0 0 64 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <g clipPath="url(#clip0_2600_175836)">
        <path d="M53.698 51.1835V43.3691L55.414 44.3987L56.4304 42.6959L45.184 35.9507L33.9376 42.6959L34.954 44.3987L36.67 43.3691V51.1703H27.3508V43.3691L29.0668 44.3987L30.0832 42.6959L18.8368 35.9507L7.5904 42.6959L8.6068 44.3987L10.3228 43.3691V51.1703H4V53.1503H10.2964H27.3376H36.6568H53.698H59.9944V51.1703H53.698V51.1835ZM19.8004 51.1835V46.5239H17.8204V51.1835H12.2632V42.1811L18.8104 38.2607L25.3576 42.1811V51.1703H19.8004V51.1835ZM46.174 51.1835V46.5239H44.194V51.1835H38.6368V42.1811L45.184 38.2607L51.7312 42.1811V51.1703H46.174V51.1835Z" />
        <path d="M31.4022 35.7528L31.9962 36.2016L32.5902 35.7528C33.0654 35.3964 44.2854 26.7768 44.2854 18.2892C44.2854 11.5044 38.7678 6 31.9962 6C25.2114 6 19.707 11.5176 19.707 18.2892C19.707 26.7768 30.9138 35.3832 31.4022 35.7528ZM31.9962 7.9668C37.6854 7.9668 42.3054 12.6 42.3054 18.276C42.3054 24.744 34.2798 31.806 31.9962 33.6804C29.7126 31.806 21.687 24.744 21.687 18.276C21.687 12.6 26.307 7.9668 31.9962 7.9668Z" />
        <path d="M31.9981 23.0147C34.6249 23.0147 36.7501 20.8763 36.7501 18.2627C36.7501 15.6359 34.6117 13.5107 31.9981 13.5107C29.3845 13.5107 27.2461 15.6491 27.2461 18.2627C27.2461 20.8763 29.3713 23.0147 31.9981 23.0147ZM31.9981 15.4775C33.5293 15.4775 34.7701 16.7183 34.7701 18.2495C34.7701 19.7807 33.5293 21.0215 31.9981 21.0215C30.4669 21.0215 29.2261 19.7807 29.2261 18.2495C29.2261 16.7183 30.4669 15.4775 31.9981 15.4775Z" />
      </g>
      <defs>
        <clipPath id="clip0_2600_175836">
          <rect width="64" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgQuizNeighborhood
