// extracted by mini-css-extract-plugin
export var borderGradient = "QuizComparisonV2-module--border-gradient--9fcdf";
export var closeButton = "QuizComparisonV2-module--close-button--ed5c3";
export var content = "QuizComparisonV2-module--content--f83cb";
export var form = "QuizComparisonV2-module--form--4c871";
export var formRow = "QuizComparisonV2-module--form-row--3b07d";
export var heading = "QuizComparisonV2-module--heading--a5ba2";
export var headingForm = "QuizComparisonV2-module--heading-form--72f13";
export var image = "QuizComparisonV2-module--image--b9005";
export var large = "QuizComparisonV2-module--large--5ab62";
export var medium = "QuizComparisonV2-module--medium--edcf6";
export var mobileImage = "QuizComparisonV2-module--mobile-image--e38b1";
export var modalContainer = "QuizComparisonV2-module--modal-container--87e57";
export var modalContent = "QuizComparisonV2-module--modal-content--adadb";
export var prev = "QuizComparisonV2-module--prev--92b96";
export var prevNextButton = "QuizComparisonV2-module--prev-next-button--fd548";
export var quiz = "QuizComparisonV2-module--quiz--8cc48";
export var subHeading = "QuizComparisonV2-module--sub-heading--16efd";
export var subHeadingLast = "QuizComparisonV2-module--sub-heading-last--330b6";