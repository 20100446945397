import React from 'react'
import clsx from 'clsx'

const SvgQuizChat = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      style={{ enableBackground: 'new 0 0 64 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M50.2761 9.23926H13.7982C11.5731 9.23926 9.75586 11.0491 9.75586 13.2816V37.5953C9.75586 39.8204 11.5657 41.6377 13.7982 41.6377H18.879V49.7521C18.879 50.1304 19.1016 50.4864 19.4427 50.6496C19.5837 50.7163 19.732 50.7534 19.8804 50.7534C20.1103 50.7534 20.3328 50.6793 20.5034 50.5309L31.6292 41.6302H50.2761C52.5013 41.6302 54.3185 39.8204 54.3185 37.5879V13.2816C54.3111 11.0565 52.5013 9.23926 50.2761 9.23926ZM52.3159 37.5953C52.3159 38.7227 51.4035 39.635 50.2761 39.635H31.2806C31.0507 39.635 30.8356 39.7092 30.6576 39.8575L20.8817 47.6753V40.6363C20.8817 40.0875 20.4292 39.635 19.8804 39.635H13.7982C12.6708 39.635 11.7585 38.7227 11.7585 37.5953V13.2816C11.7585 12.1542 12.6708 11.2419 13.7982 11.2419H50.2761C51.4035 11.2419 52.3159 12.1542 52.3159 13.2816V37.5953Z" />
      <path d="M44.1941 21.4033H19.8805C19.3316 21.4033 18.8792 21.8558 18.8792 22.4046C18.8792 22.9535 19.3316 23.406 19.8805 23.406H44.1941C44.743 23.406 45.1954 22.9535 45.1954 22.4046C45.1954 21.8558 44.7504 21.4033 44.1941 21.4033Z" />
      <path d="M32.0373 27.478H19.8805C19.3316 27.478 18.8792 27.9305 18.8792 28.4794C18.8792 29.0282 19.3316 29.4807 19.8805 29.4807H32.0373C32.5862 29.4807 33.0386 29.0282 33.0386 28.4794C33.0386 27.9305 32.5862 27.478 32.0373 27.478Z" />
    </svg>
  )
}

export default SvgQuizChat
