import React from 'react'
import clsx from 'clsx'

const SvgQuizAnalytics = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      style={{ enableBackground: 'new 0 0 64 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M22.744 37.3838H12.76C12.24 37.3838 11.824 37.7998 11.824 38.3198V51.6318C11.824 52.1518 12.24 52.5678 12.76 52.5678H22.744C23.264 52.5678 23.68 52.1518 23.68 51.6318V38.3198C23.68 37.7998 23.264 37.3838 22.744 37.3838ZM21.808 50.6958H13.696V39.2558H21.808V50.6958Z" />
      <path d="M36.992 24.0718H27.008C26.488 24.0718 26.072 24.4878 26.072 25.0078V51.6318C26.072 52.1518 26.488 52.5678 27.008 52.5678H36.992C37.512 52.5678 37.928 52.1518 37.928 51.6318V25.0078C37.928 24.4878 37.512 24.0718 36.992 24.0718ZM36.056 50.6958H27.944V25.9438H36.056V50.6958Z" />
      <path d="M51.2401 10.7598H41.2561C40.7361 10.7598 40.3201 11.1758 40.3201 11.6958V51.6318C40.3201 52.1518 40.7361 52.5678 41.2561 52.5678H51.2401C51.7601 52.5678 52.1761 52.1518 52.1761 51.6318V11.6958C52.1761 11.1758 51.7601 10.7598 51.2401 10.7598ZM50.3041 50.6958H42.1921V12.6318H50.3041V50.6958Z" />
      <path d="M12.7704 31.6638C13.02 31.6638 13.2592 31.5702 13.436 31.383L31.064 13.9422V16.6878C31.064 17.2078 31.48 17.6238 32 17.6238C32.52 17.6238 32.936 17.2078 32.936 16.6878V11.6958C32.936 11.1758 32.52 10.7598 32 10.7598H27.008C26.488 10.7598 26.072 11.1758 26.072 11.6958C26.072 12.2158 26.488 12.6318 27.008 12.6318H29.7536L12.1048 30.0518C11.7408 30.4158 11.7408 31.019 12.1048 31.383C12.2816 31.5702 12.5104 31.6638 12.7704 31.6638Z" />
    </svg>
  )
}

export default SvgQuizAnalytics
