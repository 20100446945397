import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTablet from '../Tablet'
import * as cx from './TabletLandscapePortraitPhone.module.scss'

const TabletLandscapePortraitPhone = ({
  wrapperClass = '',
  mobileSrc,
  tabletLandscapeSrc,
  tabletPortraitSrc,
  alt = '',
  lazy = true,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={702} height={460} className={cx.placeholder}></canvas>
      <DeviceMobile
        className={`${cx.mobile} mobile`}
        imageFile={mobileSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.tabletLandscape} tablet-landscape`}
        imageFile={tabletLandscapeSrc}
        imageFileAlt={`${alt} tablet landscape`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.tabletPortrait} tablet-portrait`}
        imageFile={tabletPortraitSrc}
        imageFileAlt={`${alt} tablet portrait`}
        rotate={true}
        lazy={lazy}
      />
    </div>
  )
}

TabletLandscapePortraitPhone.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.object,
  tabletLandscapeSrc: PropTypes.object,
  tabletPortraitSrc: PropTypes.object,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
}

export default TabletLandscapePortraitPhone
