import React from 'react'
import clsx from 'clsx'

const SvgQuizAccessibility = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      style={{ enableBackground: 'new 0 0 64 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M31.994 20.0045C34.1971 20.0045 35.9997 18.2145 35.9997 15.9989C35.9997 13.7832 34.2096 11.9932 31.994 11.9932C29.7908 11.9932 27.9883 13.7832 27.9883 15.9989C27.9883 18.2145 29.7908 20.0045 31.994 20.0045ZM31.994 14.0085C33.0955 14.0085 33.9968 14.9098 33.9968 16.0114C33.9968 17.1129 33.0955 18.0142 31.994 18.0142C30.8924 18.0142 29.9911 17.1129 29.9911 16.0114C29.9911 14.9098 30.8924 14.0085 31.994 14.0085Z" />
      <path d="M31.9937 52C44.1485 52 53.9875 42.1485 53.9875 30.0063C53.9875 17.864 44.1485 8 31.9937 8C19.839 8 10 17.8515 10 30.0063C10.0125 42.1485 19.8515 51.9875 31.9937 52ZM31.9937 10.0028C43.0344 10.0028 51.9972 18.9531 51.9972 30.0063C51.9972 41.0595 43.0344 50.0097 31.9937 50.0097C20.9531 50.0097 11.9903 41.0469 11.9903 30.0063C12.0028 18.9656 20.9531 10.0154 31.9937 10.0028Z" />
      <path d="M20.4896 27.9659C20.6523 27.9909 20.8276 28.0035 20.9903 28.0035H26.9863V31.2456C26.9863 31.6712 26.9362 32.0842 26.8236 32.4973L24.0697 43.1375C23.6566 44.8023 24.683 46.4922 26.3479 46.9053C28.0128 47.3184 29.7027 46.2919 30.1158 44.6271C30.1158 44.6145 30.1283 44.602 30.1283 44.5895L31.9809 36.4905L33.8335 44.5895C34.1966 46.2669 35.8489 47.3434 37.5263 46.9804C39.2037 46.6174 40.2802 44.965 39.9172 43.2877C39.9047 43.2251 39.8921 43.175 39.8796 43.1124L37.1508 32.4973C37.0506 32.0842 36.988 31.6712 36.988 31.2456V28.0035H42.8839C44.3735 28.016 45.6628 26.9645 45.9507 25.4999C46.2261 23.8601 45.1246 22.3204 43.4973 22.045C43.3345 22.02 43.1593 22.0074 42.9966 22.0074H21.103C19.6133 21.9949 18.324 23.0464 18.0361 24.511C17.7607 26.1383 18.8623 27.6905 20.4896 27.9659ZM20.0139 24.8365C20.1391 24.3357 20.5897 23.9978 21.103 24.0103H42.9966C43.5473 24.0103 43.998 24.4609 43.998 25.0117C43.998 25.0743 43.998 25.1244 43.9855 25.187C43.8603 25.6877 43.4096 26.0256 42.8964 26.0131H37.0005C35.899 26.0131 34.9977 26.9144 34.9977 28.016V31.2456C34.9977 31.8339 35.0728 32.4222 35.223 32.998L37.9644 43.6256C38.1146 44.2265 37.7516 44.8274 37.1508 44.9776C36.8628 45.0527 36.5499 45.0026 36.287 44.8399C36.0367 44.6897 35.8489 44.4268 35.7863 44.1389L33.5206 34.2123C33.3328 33.3611 32.4816 32.8353 31.6429 33.0231C31.0546 33.1608 30.5914 33.6114 30.4537 34.2123L28.188 44.1389C28.0503 44.7397 27.4495 45.1153 26.8486 44.9776H26.8361C26.2478 44.8274 25.8847 44.2265 26.0224 43.6382L28.7638 32.998C28.914 32.4222 28.9892 31.8339 28.9892 31.2456V28.0035C28.9892 26.9019 28.0879 26.0006 26.9863 26.0006H20.9903C20.4395 26.0006 19.9889 25.55 19.9889 24.9992C19.9889 24.9491 20.0014 24.8865 20.0139 24.8365Z" />
    </svg>
  )
}

export default SvgQuizAccessibility
