import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'

const Modal = ({
  containerClassName = '',
  contentClassName = '',
  children,
  childFunc,
  title,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (childFunc) {
      childFunc.current = { openModal, closeModal }
    }
  })

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="modal" onClose={closeModal}>
        <div className={clsx('modal-container', containerClassName)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="modal-overlay" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="modal-tricker" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={clsx('modal-content', contentClassName)}>
              {title && (
                <Dialog.Title as="h3" className="modal-title">
                  {title}
                </Dialog.Title>
              )}
              {description && (
                <div className="modal-description">
                  <p>{description}</p>
                </div>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
