import React from 'react'
import clsx from 'clsx'

const SvgQuizIdx = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 60"
      style={{ enableBackground: 'new 0 0 64 60' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M51.9923 24.5158C51.6563 19.4638 49.5083 14.7118 45.9443 11.1238C42.2483 7.41584 37.2323 5.33984 32.0003 5.33984C26.7683 5.33984 21.7523 7.41584 18.0563 11.1238C14.4923 14.7118 12.3443 19.4638 12.0083 24.5158C11.8283 27.2758 12.2363 30.0478 13.1963 32.6398C14.1443 35.2198 15.6803 37.6558 17.7563 39.8878L31.3763 54.3958C31.5323 54.5638 31.7603 54.6598 31.9883 54.6598C32.2163 54.6598 32.4443 54.5638 32.6003 54.3958L46.2203 39.8878C48.3083 37.6678 49.8443 35.2318 50.7803 32.6398C51.7523 30.0478 52.1603 27.2878 51.9923 24.5158ZM49.2203 32.0758C48.3563 34.4518 46.9403 36.6958 45.0083 38.7478L32.0003 52.5958L18.9923 38.7358C17.0603 36.6838 15.6443 34.4398 14.7803 32.0638C13.8923 29.6878 13.5203 27.1558 13.6883 24.6238C13.9883 19.9798 15.9683 15.5998 19.2443 12.2998C22.6283 8.91584 27.2123 7.00784 32.0003 7.00784C36.7883 7.00784 41.3723 8.91584 44.7563 12.2998C48.0323 15.5998 50.0123 19.9798 50.3123 24.6238C50.4683 27.1558 50.0963 29.6998 49.2203 32.0758Z" />
      <path d="M32.1564 10.0918C32.1084 10.0918 32.0604 10.0918 32.0124 10.0918C28.3524 10.0918 24.8484 11.5558 22.2804 14.1718C19.6284 16.8358 18.1284 20.4358 18.1044 24.2038C18.0204 32.0638 24.1884 38.5198 31.8564 38.6038C31.9044 38.6038 31.9524 38.6038 32.0004 38.6038C39.5964 38.6038 45.8244 32.3038 45.9084 24.5038C45.9684 20.7478 44.5404 17.1118 41.9484 14.3878C39.4044 11.6758 35.8644 10.1278 32.1564 10.0918ZM32.0004 36.9238C31.9524 36.9238 31.9164 36.9238 31.8684 36.9238C29.4204 36.8998 27.0444 36.1198 25.0524 34.6918C25.2204 30.9718 28.2804 28.0438 32.0004 28.0438C35.7204 28.0438 38.7924 30.9718 38.9484 34.6918C36.9204 36.1318 34.4844 36.9238 32.0004 36.9238ZM40.4844 33.3838C39.7164 29.3038 36.1524 26.3518 32.0004 26.3518C27.8484 26.3518 24.2844 29.3158 23.5164 33.3838C18.5484 28.4038 18.5244 20.3518 23.4564 15.3478C25.7364 13.0198 28.8684 11.7358 32.1324 11.7598C35.3964 11.7958 38.4924 13.1518 40.7244 15.5278C45.4884 20.5798 45.3684 28.4758 40.4844 33.3838Z" />
      <path d="M32.0007 13.332C28.9527 13.332 26.4807 15.804 26.4807 18.852C26.4807 21.9 28.9527 24.36 32.0007 24.372C35.0487 24.372 37.5207 21.9 37.5207 18.852C37.5207 15.804 35.0487 13.332 32.0007 13.332ZM32.0007 22.692C29.8767 22.692 28.1607 20.976 28.1607 18.852C28.1607 16.728 29.8767 15.012 32.0007 15.012C34.1247 15.012 35.8407 16.728 35.8407 18.852C35.8407 20.976 34.1247 22.692 32.0007 22.692Z" />
    </svg>
  )
}

export default SvgQuizIdx
