import React from 'react'
import clsx from 'clsx'

const SvgQuizPencil = ({ className = '', strokeColor = '#009BBB' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 60"
      style={{ enableBackground: 'new 0 0 120 60' }}
      className={clsx(className)}
      fill="none"
    >
      <path
        d="M59.9987 13.332C50.7937 13.332 43.332 20.7937 43.332 29.9987C43.332 39.2037 50.7937 46.6654 59.9987 46.6654C69.2037 46.6654 76.6654 39.2037 76.6654 29.9987C76.6654 20.7937 69.2037 13.332 59.9987 13.332Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.332 45.2774V33.3307L59.9987 21.6641L66.6654 33.3307V45.2774"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.332 33.332C53.332 33.332 55.2104 34.9987 56.6654 34.9987C58.1204 34.9987 59.9987 33.332 59.9987 33.332C59.9987 33.332 61.877 34.9987 63.332 34.9987C64.787 34.9987 66.6654 33.332 66.6654 33.332"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgQuizPencil
