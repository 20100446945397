import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

import * as cx from './CustomInputs.module.scss'

import SvgArrowAlt from '@svg/arrowAlt.inline'

const CustomInputs = ({
  layout = 'row',
  inputType = 'radio',
  wrapperClassName = '',
  value = '',
  items = [],
  hidePrev = false,
  changeState,
  onPrev,
  onNext,
}) => {
  const handleChangevalue = (e) => {
    changeState(e.target.value)
  }

  let disableNext

  if (inputType === 'radio') {
    disableNext = value === ''
  } else {
    disableNext = value.length === 0
  }

  return (
    <div className={clsx(cx.wrapper, wrapperClassName)}>
      <div
        className={clsx(
          cx.items,
          (layout === 'column' || layout === 'column-three') && cx.column,
          layout === 'column-three' && cx.columnThree
        )}
      >
        {items.map((item, index) => {
          const inputValue = removeElementTags(item.title)
          let isActive

          if (inputType === 'radio') {
            isActive = inputValue === value
          } else {
            isActive = value.includes(inputValue)
          }

          return (
            <label key={index} className={clsx(isActive && cx.active)}>
              <input
                type={inputType}
                value={inputValue}
                checked={isActive}
                onChange={handleChangevalue}
              />
              {item.icon}
              <span>
                <strong className="subtitle-5">{parse(item.title)}</strong>
                <strong className="default-body">{item.description}</strong>
              </span>
            </label>
          )
        })}
      </div>

      <div className={clsx(cx.submit)}>
        {hidePrev === false && (
          <button
            type="button"
            className={clsx('primary-button', 'large', 'alt', cx.prev)}
            onClick={(e) => {
              e.preventDefault()
              onPrev()
            }}
          >
            <SvgArrowAlt />
            <span className="mobile-no-tablet-only">Back to previous</span>
          </button>
        )}
        <button
          type="button"
          className={clsx('primary-button', 'large', disableNext && 'inactive')}
          disabled={disableNext}
          onClick={(e) => {
            e.preventDefault()
            onNext()
          }}
        >
          Continue to Next
        </button>
      </div>
    </div>
  )
}

CustomInputs.propTypes = {
  layout: PropTypes.oneOf(['row', 'column', 'column-three']),
  inputType: PropTypes.oneOf(['radio', 'checkbox']),
  wrapperClassName: PropTypes.string,
  items: PropTypes.array,
  hidePrev: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
}

export default CustomInputs
