// extracted by mini-css-extract-plugin
export var carousel = "Banner-module--carousel--fb539";
export var content = "Banner-module--content--6d26f";
export var deviceV1 = "Banner-module--device-v1--10f0e";
export var deviceV2 = "Banner-module--device-v2--32c6f";
export var deviceV3 = "Banner-module--device-v3--612bb";
export var mono = "Banner-module--mono--1250f";
export var sliders = "Banner-module--sliders--86996";
export var splide__pagination = "Banner-module--splide__pagination--766f0";
export var topAgents = "Banner-module--top-agents--591b7";
export var videoThumbnail = "Banner-module--video-thumbnail--f9562";
export var wrapper = "Banner-module--wrapper--13776";